<template>
  <div
      v-bind:disabled="locked"
     
      v-bind:class="statusClass">
    
      <button
          @click="addPick(sport_id,match_id,market_name,market_id,home_team,away_team,pick, live)"
          v-bind:id="getOddID(match_id,market_id,outcome_id,specifier,boosted ? 'boosted' : 'odd')"
          v-bind:oddStatus="oddStatus"
          v-bind:oddValue="odd"
          v-bind:oddActive="oddActive"
          v-bind:producerId="producer_id"
          v-bind:producerStatus="producerStatus"
          style="width: 100%;text-align: center;height: fit-content;color: #000000c7;font-size: 10px !important;font-weight: 700 !important;border-radius: 5px;"
          class="btn odd-btn small-txt" :class="picked" >
        <div class="grey-text text-center odd-indicator ">
          <span style="width: 0.5em;height: 0.5em;margin: 0.1em;" class="odd-direction arrow green up" v-bind:class="directionClass"></span>
          <span v-text="alias"></span>
        </div>
<!--        <span v-if="!locked" class="odd-direction" v-bind:class="directionClass"></span>-->
        <span v-if="locked"></span>
        <span v-else class="odd-number">{{ odd }}</span>
        <span v-if="locked" style="height: 1.5em;">
          <LockIcon :size="iconSize" fill-color="white"></LockIcon>
        </span>

      </button>

  </div>

</template>

<script>

const LockIcon= () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/Lock.vue');

export default {
name: "Odd",
props: {
  pick: {
    required: true
  },
  away_team: {
    required: true
  },
  home_team: {
    required: true
  },
  market_name: {
    required: true
  },
  sport_id: {
    required: true
  },
  show_direction: {
    required: false,
    default: true
  },
  previous_odds: {
    required: true
  },
  odds: {
    required: true
  },
  probability: {
    required: false,
    default: 0
  },
  match_id: {
    required: true
  },
  market_id: {
    required: true
  },
  outcome_id: {
    required: true
  },
  specifier: {
    required: true
  },
  active: {
    required: true
  },
  status: {
    required: true
  },
  producer_id: {
    required: true
  },
  producer_status: {
    required: true
  },
  alias: {
    required: true,
    default: '',
  },
  boosted: {
    required: false,
    default: false,
  },
  live: {
    required: false,
    default: false
  }
},
methods: {

  getPicked: function(match_id,id){
          var betsObject = this.$store.state.betslip;
          if(betsObject.picks !== undefined && betsObject.picks[match_id] !== undefined && betsObject.picks[match_id].id == id){
            // console.log("Is Picked")
            return true;
          }else{
            // console.log("Is Not Picked" + id)
          }
        },
  oddsChange: function(payload){

    var vm = this;

    vm.oddStatus = payload.status;
    vm.oddActive = payload.active;

    if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

      vm.previousOdds = vm.odd;
      vm.odd = payload.odd;
    }

    vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
    vm.getStatusClass();

    vm.directionClass = vm.getOddDirectionClass();

  },
  getOddDirectionClass: function () {

    if(parseInt(this.producerStatus) === 0 || parseInt(this.oddActive) === 0 || parseInt(this.oddStatus) !== 0) {

      return '';

    }

    if(parseFloat(this.odd) >= parseFloat(this.previousOdds) ) {
      return 'arrow green up';
    }
    return 'arrow red down';

  },
  getStatusClass: function (){

    this.statusClass = this.locked ? 'lock' : '';

  }
},
mounted: function () {
  // console.log(this._betslip);
  console.log("App Mounted")
  this.odd = this.odds;
  this.oddStatus = this.status;
  this.oddActive = this.active;
  this.previousOdds = this.previous_odds;
  this.producerStatus = this.producer_status;
  //  console.log(this.$store.state.betslip.picks["28303984"]);

  if(parseInt(this.producer_id) === 1 ) {

    this.locked = parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0 || parseInt(this.producerStatus) === 0;

  } else {

    this.locked = parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0;

  }

  this.getStatusClass();

   this.id = this.getOddID(this.match_id,this.market_id,this.outcome_id,this.specifier,this.boosted ? 'boosted' : 'odd');
  // this.id=0;
  // if(this.$store.state.betslip.picks !== undefined && this.$store.state.betslip.picks[this.match_id] !== undefined && this.$store.state.betslip.picks[this.match_id].id == this.id){
  //   console.log("item in betslip:" + this.id);
  //   $("#"+this.id).addClass("picked");
  //   // if(document.getElementById(this.id) !== null){
  //   //   $("#"+this.id).addClass("picked");
  //   // }
  // }
  this.directionClass = this.getOddDirectionClass();
  var vm = this;
  
  this.EventBus.$on('odds:'+vm.id,function(payload){

    vm.oddStatus = payload.status;
    vm.oddActive = payload.active;

    if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

      vm.previousOdds = vm.odd;
      vm.odd = payload.odd;
    }

    vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
    vm.getStatusClass();

    vm.directionClass = vm.getOddDirectionClass();

  });

  this.EventBus.$on('producer:status',function(payload){

    var producer_id = payload.producer_id;
    var producer_status = payload.producer_status;

    if(parseInt(producer_id) === 1 ) {

      vm.producerStatus = producer_status;
      vm.directionClass = vm.getOddDirectionClass();
      vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(producer_status) === 0;
      vm.getStatusClass();
    }

  });

  this.EventBus.$on('betstop:match-:'+vm.match_id,function(){

    console.log('GOt betstop:match-'+vm.match_id)
    vm.oddStatus = 1; // deactivate
    vm.locked = true;
    vm.getStatusClass();

  });

  this.EventBus.$on('status:match-:'+vm.match_id+':market-'+vm.market_id,function(payload){

    vm.oddStatus = payload.status; // deactivate
    vm.locked = parseInt(vm.oddStatus) > 0;
    vm.getStatusClass();

  });

  this.EventBus.$on('oddschange:match-:'+vm.match_id+':market-'+vm.market_id+':outcome-'+vm.outcome_id+':specifier-'+vm.specifier,function(payload){

    vm.oddActive = payload.active;

    if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

      vm.previousOdds = vm.odd;
      vm.odd = payload.odd;
    }

    vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
    vm.getStatusClass();

    vm.directionClass = vm.getOddDirectionClass();

  });


},
data: function (){

  return {
    iconSize: 13,
    id: '',
    odd: '',
    directionClass: '',
    oddStatus: '',
    oddActive:'',
    previousOdds:'',
    producerStatus: '',
    locked: false,
    statusClass:'',
  }
},
computed: {
  picked:function(){
          var betsObject = this.$store.state.betslip;
          if(betsObject.picks !== undefined && betsObject.picks[this.match_id] !== undefined && betsObject.picks[this.match_id].id == this.id){
          // console.log("Is Picked")
            
            return "picked";
          }else{
            // console.log("not picked: " + this.id)
            // document.getElementById(this.id).classList.remove("picked");
            return "";
          }
  },
  _betslip: function(){
    return this.$store.state.betslip
  },
  isHot: function (){

    return parseFloat(this.probability) > 0.65
  }

},
components: {
  LockIcon
}
}
</script>